/* eslint-disable indent */
/* eslint-disable comma-dangle */
/* eslint-disable no-trailing-spaces */

type StaticImages = {
    readonly [fileName: string]: {
        uri?: string;
    };
}

const imageStaticSourcesByFileName: StaticImages = {
    '8u77wAejKR6bNHjfMuxgi7.png': { uri: '/images/8u77wAejKR6bNHjfMuxgi7.png' },
'qz8LFMRuC5FcGzE4sX58br.png': { uri: '/images/qz8LFMRuC5FcGzE4sX58br.png' },
'vR91g6yG1funrKLqXFV2iw.png': { uri: '/images/vR91g6yG1funrKLqXFV2iw.png' },
'6SbiPp9ZpxJ3pEhHj2DcUd.png': { uri: '/images/6SbiPp9ZpxJ3pEhHj2DcUd.png' },
'hERFVtCSUKUJSA9H2Gdxsv.png': { uri: '/images/hERFVtCSUKUJSA9H2Gdxsv.png' },
'5mf4gx64WmKdMXMa9AzAKK.png': { uri: '/images/5mf4gx64WmKdMXMa9AzAKK.png' },
'j6Cb4tA4i5KmLTEQkpaiGB.png': { uri: '/images/j6Cb4tA4i5KmLTEQkpaiGB.png' },
'hihDRH2NTWkj4uRk5vdq25.png': { uri: '/images/hihDRH2NTWkj4uRk5vdq25.png' },
'58vqJm6La45bkK8PXyxMMn.png': { uri: '/images/58vqJm6La45bkK8PXyxMMn.png' },
'2KT5FRdv8bvh5BBjM81vPd.png': { uri: '/images/2KT5FRdv8bvh5BBjM81vPd.png' },
'rUMZbeTxy5XfDHaGeVacDo.png': { uri: '/images/rUMZbeTxy5XfDHaGeVacDo.png' },
'5b1D8w87qnNT692G2mnc6r.png': { uri: '/images/5b1D8w87qnNT692G2mnc6r.png' },
'kv5gKNURCFAVZ8hv3X6a4S.png': { uri: '/images/kv5gKNURCFAVZ8hv3X6a4S.png' },
'iFwm4Cx7kXihBULQRCER5v.png': { uri: '/images/iFwm4Cx7kXihBULQRCER5v.png' },
'7gUACkExSBmzboYhCtwbCA.jpg': { uri: '/images/7gUACkExSBmzboYhCtwbCA.jpg' },
'b7Kbcp8WG4Sc3BvyjmwGi5.png': { uri: '/images/b7Kbcp8WG4Sc3BvyjmwGi5.png' },
'oQ4D2kWgY9PHAQWG99fz86.png': { uri: '/images/oQ4D2kWgY9PHAQWG99fz86.png' },
'3TUUZH63N2fGax6AzuCkXf.png': { uri: '/images/3TUUZH63N2fGax6AzuCkXf.png' },
'2JU7RqMDmLWXVDPrkt64m5.png': { uri: '/images/2JU7RqMDmLWXVDPrkt64m5.png' },
'jM9R1qbZ4turZqR8koB8bz.png': { uri: '/images/jM9R1qbZ4turZqR8koB8bz.png' },
'9GKbvJfFhRRk2vchoVy9rE.png': { uri: '/images/9GKbvJfFhRRk2vchoVy9rE.png' },
'wwUbEc1zMHd9PR3UL1gq7o.png': { uri: '/images/wwUbEc1zMHd9PR3UL1gq7o.png' },
'w5QKsPEnDhjeUgo8R3rb2y.png': { uri: '/images/w5QKsPEnDhjeUgo8R3rb2y.png' },
'rbBo9KLTEAZuZaQuPhSJuz.png': { uri: '/images/rbBo9KLTEAZuZaQuPhSJuz.png' },
't15A25PRJ9aHudgWAK3VEB.png': { uri: '/images/t15A25PRJ9aHudgWAK3VEB.png' },
'kdVAMxcF5NzQfyedUCWhXX.png': { uri: '/images/kdVAMxcF5NzQfyedUCWhXX.png' },
'8aFxdrTVekF8YySHvtNuEB.png': { uri: '/images/8aFxdrTVekF8YySHvtNuEB.png' },
'sc3jAV5HL1AUVSbSKrU4xm.png': { uri: '/images/sc3jAV5HL1AUVSbSKrU4xm.png' },
'2NUUKU7h4qrpRprCkNbvZH.png': { uri: '/images/2NUUKU7h4qrpRprCkNbvZH.png' },
'nvWV3DzvGvTgLCfXrgiuSB.png': { uri: '/images/nvWV3DzvGvTgLCfXrgiuSB.png' },
's7HX8V4eem7eG1hCVqpu72.png': { uri: '/images/s7HX8V4eem7eG1hCVqpu72.png' },
'txVrwAS6xgARyeK3GRaHAJ.png': { uri: '/images/txVrwAS6xgARyeK3GRaHAJ.png' },
'itxsqFXGiSfCRaxUnYgYSY.png': { uri: '/images/itxsqFXGiSfCRaxUnYgYSY.png' },
'hchuGivkhFv44DWZTSGuWR.png': { uri: '/images/hchuGivkhFv44DWZTSGuWR.png' },
'fE7J8YbENnRUBKZw56XjeN.png': { uri: '/images/fE7J8YbENnRUBKZw56XjeN.png' },
'uBhv44K3Pdz39zs4DtGiFH.png': { uri: '/images/uBhv44K3Pdz39zs4DtGiFH.png' },
'dBbFN2TyKnrkzmsoGiosb4.png': { uri: '/images/dBbFN2TyKnrkzmsoGiosb4.png' },
'jsRcg871yss9JXu4GDHikC.png': { uri: '/images/jsRcg871yss9JXu4GDHikC.png' },
'rKM13WJE94BtxfPuZWFxrM.png': { uri: '/images/rKM13WJE94BtxfPuZWFxrM.png' },
'9RDummmeBtVDDpnSp3rKTF.png': { uri: '/images/9RDummmeBtVDDpnSp3rKTF.png' },
'cMhFLd8E4iEQo34DP2L833.png': { uri: '/images/cMhFLd8E4iEQo34DP2L833.png' },
'cVkg7LwRXuH7vVBXhJUc1u.png': { uri: '/images/cVkg7LwRXuH7vVBXhJUc1u.png' },
'hTgxcrnDzPiyXXYoGYpfjk.png': { uri: '/images/hTgxcrnDzPiyXXYoGYpfjk.png' },
'k3HguC7a1yAF91YA9HSRXL.png': { uri: '/images/k3HguC7a1yAF91YA9HSRXL.png' },
'p1dd4ngXbgBPUeTnaanJor.png': { uri: '/images/p1dd4ngXbgBPUeTnaanJor.png' },
'1Lgc4XSF8iytPCh33FSuXG.png': { uri: '/images/1Lgc4XSF8iytPCh33FSuXG.png' },
'gMQkKiSeqT4nAUmS1m5Lzi.png': { uri: '/images/gMQkKiSeqT4nAUmS1m5Lzi.png' },
'9pEZAqsvCstv62FUzruUPP.png': { uri: '/images/9pEZAqsvCstv62FUzruUPP.png' },
'xcywiBSSSbN9TQSmTbULYQ.png': { uri: '/images/xcywiBSSSbN9TQSmTbULYQ.png' },
'1uKoyY8m3SpNdaerUx4Djc.png': { uri: '/images/1uKoyY8m3SpNdaerUx4Djc.png' },
'ukbpbVTiizqCLsbYaCxq9o.png': { uri: '/images/ukbpbVTiizqCLsbYaCxq9o.png' },
'5PqPRmPGnDxNrrTfd12BSZ.png': { uri: '/images/5PqPRmPGnDxNrrTfd12BSZ.png' },
'2deUz5UCPGsEMpjeH5xYqd.png': { uri: '/images/2deUz5UCPGsEMpjeH5xYqd.png' },
'pAMMYaDXqVGAtXATeasp8h.png': { uri: '/images/pAMMYaDXqVGAtXATeasp8h.png' },
'dBnaekFub4qqA4dLgUYmoK.png': { uri: '/images/dBnaekFub4qqA4dLgUYmoK.png' },
'6cwPowqE2bYXSs2zoB7iU5.png': { uri: '/images/6cwPowqE2bYXSs2zoB7iU5.png' },
'j4nRkJ17eBQvovQmFmQwC7.png': { uri: '/images/j4nRkJ17eBQvovQmFmQwC7.png' },
'swpULGPq62ggJd9RQRs3SR.png': { uri: '/images/swpULGPq62ggJd9RQRs3SR.png' },
's7ALHr1VEc1DRgoAfKxig4.png': { uri: '/images/s7ALHr1VEc1DRgoAfKxig4.png' },
'w38f5cvDiNtxtcqnoMayKh.png': { uri: '/images/w38f5cvDiNtxtcqnoMayKh.png' },
'fW2awsFK6K6Ddy5fmJA9Km.jpg': { uri: '/images/fW2awsFK6K6Ddy5fmJA9Km.jpg' }
}

export default imageStaticSourcesByFileName
